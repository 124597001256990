//General Setting
export const FETCH_GENERAL_SETTING_LIST = 'FETCH_GENERAL_SETTING_LIST'
export const RECEIVE_GENERAL_SETTING_LIST = 'RECEIVE_GENERAL_SETTING_LIST'
export const FAILED_GENERAL_SETTING_LIST = 'FAILED_GENERAL_SETTING_LIST'
export const UPSERT_GENERAL_SETTING = 'UPSERT_GENERAL_SETTING'

//Budget Setting
export const FETCH_BUDGET_SETTING_LIST = 'FETCH_BUDGET_SETTING_LIST'
export const RECEIVE_BUDGET_SETTING_LIST = 'RECEIVE_BUDGET_SETTING_LIST'
export const FAILED_BUDGET_SETTING_LIST = 'FAILED_BUDGET_SETTING_LIST'
export const UPSERT_BUDGET_SETTING = 'UPSERT_BUDGET_SETTING'

