import {
  FETCH_COMPANY_LIST,
  RECEIVE_COMPANY_LIST,
  FAILED_COMPANY_LIST,
  EDIT_COMPANY_PROFILE,
} from "./companies.types";

const defaultCompaniesState = {
  companiesData: [],
  totalComapnies: 0,
  companySelect: [],
  success: true,
  inProgress: false,
};

export function companiesReducer(state = defaultCompaniesState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_COMPANY_LIST:
      return {
        companiesData: [],
        success: true,
        deviceTotal: 0,
        companySelect: [],
        inProgress: true,
      };

    case RECEIVE_COMPANY_LIST:
      var _totalDevice = payload.result.length;
      var _selectDevices = [];
      if (payload.result !== []) {
        var _companiesData = payload.result;

        _companiesData.forEach((p, index) => {
          var _util = {
            label: _companiesData[index].company,
            value: _companiesData[index].id,
          };
          _selectDevices.push(_util);
        });
      }
      return Object.assign({}, state, {
        companiesData: payload,
        success: true,
        totalComapnies: _totalDevice,
        companySelect: _selectDevices,
        inProgress: false,
      });

    case FAILED_COMPANY_LIST:
      return {
        companiesData: [],
        success: false,
        totalComapnies: 0,
        companySelect: [],
        inProgress: false,
      };

    case EDIT_COMPANY_PROFILE:
      return state;

    default:
      return state;
  }
}
