import {
  FETCH_LL_USAGE_SCHEDULE_LIST,
  RECEIVE_LL_USAGE_SCHEDULE_LIST,
  FAILED_LL_USAGE_SCHEDULE_LIST,
} from "./usage-schedules.types";

const defaultLLUsageScheduleState = {
  llUsageScheduleData: [],
  llTotalUsageSchedule: 0,
  llUsageScheduleSelect: [],
  success: true,
  inProgress: false,
};

export function fetchUsageSchedules(
  state = defaultLLUsageScheduleState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LL_USAGE_SCHEDULE_LIST:
      return {
        llUsageScheduleData: [],
        success: true,
        llTotalUsageSchedule: 0,
        llUsageScheduleSelect: [],
        inProgress: true,
      };

    case RECEIVE_LL_USAGE_SCHEDULE_LIST:
      var _totalUsageSchedule = payload.result.length;
      var _selectUsageSchedule = [];
      if (payload.result !== []) {
        var _usageScheduleData = payload.result;

        _usageScheduleData.forEach((p, index) => {
          var _usageSched = {
            label:
              _usageScheduleData[index].usage_sched_name +
              " - " +
              _usageScheduleData[index].usage_sched_desc,
            value: _usageScheduleData[index].id,
          };
          _selectUsageSchedule.push(_usageSched);
        });
      }
      return Object.assign({}, state, {
        llUsageScheduleData: payload,
        success: true,
        llTotalUsageSchedule: _totalUsageSchedule,
        llUsageScheduleSelect: _selectUsageSchedule,
        inProgress: false,
      });

    case FAILED_LL_USAGE_SCHEDULE_LIST:
      return {
        llUsageScheduleData: [],
        success: false,
        llTotalUsageSchedule: 0,
        llUsageScheduleSelect: [],
        inProgress: false,
      };

    default:
      return state;
  }
}
