import {
  FETCH_VISUAL_MODEL_LIST,
  RECEIVE_VISUAL_MODEL_LIST,
  FAILED_VISUAL_MODEL_LIST,
  CREATE_VISUAL_MODEL,
  EDIT_VISUAL_MODEL,
  DELETE_VISUAL_MODEL,
  FETCH_VISUAL_MODEL_DEVICE_LIST,
  RECEIVE_VISUAL_MODEL_DEVICE_LIST,
  FAILED_VISUAL_MODEL_DEVICE_LIST,
  CREATE_VISUAL_MODEL_DEVICE,
  EDIT_VISUAL_MODEL_DEVICE,
  DELETE_VISUAL_MODEL_DEVICE,
  FETCH_VISUAL_MODEL_SENSOR_LIST,
  RECEIVE_VISUAL_MODEL_SENSOR_LIST,
  FAILED_VISUAL_MODEL_SENSOR_LIST,
  CREATE_VISUAL_MODEL_SENSOR,
  EDIT_VISUAL_MODEL_SENSOR,
  DELETE_VISUAL_MODEL_SENSOR,
  FETCH_VISUAL_MODEL_UTILITIES_LIST,
  RECEIVE_VISUAL_MODEL_UTILITIES_LIST,
  FAILED_VISUAL_MODEL_UTILITIES_LIST,
  CREATE_VISUAL_MODEL_UTILITIES,
  EDIT_VISUAL_MODEL_UTILITIES,
  DELETE_VISUAL_MODEL_UTILITIES,
} from "./visual-modeling.types";

//Visual Model
const defaultVisualModelState = {
  visualModelData: [],
  totalVisualModel: 0,
  visualModelSelect: [],
  success: true,
  inProgress: false,
};

export function visualModelReducer(state = defaultVisualModelState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_VISUAL_MODEL_LIST:
      return {
        visualModelData: [],
        success: true,
        deviceTotal: 0,
        visualModelSelect: [],
        inProgress: true,
      };

    case RECEIVE_VISUAL_MODEL_LIST:
      var _totalVisualModel = payload.result.length;
      var _selectVisualModel = [];
      if (payload.result !== []) {
        var _visualModelData = payload.result;

        _visualModelData.forEach((p, index) => {
          var _vm = {
            label: _visualModelData[index].layout_name,
            value: _visualModelData[index].id,
          };
          _selectVisualModel.push(_vm);
        });
      }
      return Object.assign({}, state, {
        visualModelData: payload,
        success: true,
        totalVisualModel: _totalVisualModel,
        visualModelSelect: _selectVisualModel,
        inProgress: false,
      });

    case FAILED_VISUAL_MODEL_LIST:
      return {
        visualModelData: [],
        success: false,
        totalVisualModel: 0,
        visualModelSelect: [],
        inProgress: false,
      };

    case CREATE_VISUAL_MODEL:
      return state;

    case EDIT_VISUAL_MODEL:
      return state;

    case DELETE_VISUAL_MODEL:
      return state;

    default:
      return state;
  }
}

//Visual Model Device
const defaultVisualModelDeviceState = {
  visualModelDeviceData: [],
  totalVisualModelDevice: 0,
  visualModelDeviceSelect: [],
  success: true,
  inProgress: false,
};

export function visualModelDeviceReducer(
  state = defaultVisualModelDeviceState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_VISUAL_MODEL_DEVICE_LIST:
      return {
        visualModelDeviceData: [],
        success: true,
        deviceTotal: 0,
        visualModelDeviceSelect: [],
        inProgress: true,
      };

    case RECEIVE_VISUAL_MODEL_DEVICE_LIST:
      var _totalVisualModelDevice = payload.result.length;
      var _selectVisualModelDevice = [];
      if (payload.result !== []) {
        var _visualModelDeviceData = payload.result;

        _visualModelDeviceData.forEach((p, index) => {
          var _vm = {
            label: _visualModelDeviceData[index].sensor_name,
            value: _visualModelDeviceData[index].id_sensor,
          };
          _selectVisualModelDevice.push(_vm);
        });
      }
      return Object.assign({}, state, {
        visualModelDeviceData: payload,
        success: true,
        totalVisualModelDevice: _totalVisualModelDevice,
        visualModelDeviceSelect: _selectVisualModelDevice,
        inProgress: false,
      });

    case FAILED_VISUAL_MODEL_DEVICE_LIST:
      return {
        visualModelDeviceData: [],
        success: false,
        totalVisualModelDevice: 0,
        visualModelDeviceSelect: [],
        inProgress: false,
      };

    case CREATE_VISUAL_MODEL_DEVICE:
      return state;

    case EDIT_VISUAL_MODEL_DEVICE:
      return state;

    case DELETE_VISUAL_MODEL_DEVICE:
      return state;

    default:
      return state;
  }
}

//Visual Model Sensor
const defaultVisualModelSensorState = {
  visualModelSensorData: [],
  totalVisualModelSensor: 0,
  visualModelSensorSelect: [],
  success: true,
  inProgress: false,
};

export function visualModelSensorReducer(
  state = defaultVisualModelSensorState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_VISUAL_MODEL_SENSOR_LIST:
      return {
        visualModelSensorData: [],
        success: true,
        deviceTotal: 0,
        visualModelSensorSelect: [],
        inProgress: true,
      };

    case RECEIVE_VISUAL_MODEL_SENSOR_LIST:
      var _totalVisualModelSensor = payload.result.length;
      var _selectVisualModelSensor = [];
      if (payload.result !== []) {
        var _visualModelSensorData = payload.result;

        _visualModelSensorData.forEach((p, index) => {
          var _vm = {
            label: _visualModelSensorData[index].sensor_name,
            value: _visualModelSensorData[index].id_sensor,
          };
          _selectVisualModelSensor.push(_vm);
        });
      }
      return Object.assign({}, state, {
        visualModelSensorData: payload,
        success: true,
        totalVisualModelSensor: _totalVisualModelSensor,
        visualModelSensorSelect: _selectVisualModelSensor,
        inProgress: false,
      });

    case FAILED_VISUAL_MODEL_SENSOR_LIST:
      return {
        visualModelSensorData: [],
        success: false,
        totalVisualModelSensor: 0,
        visualModelSensorSelect: [],
        inProgress: false,
      };

    case CREATE_VISUAL_MODEL_SENSOR:
      return state;

    case EDIT_VISUAL_MODEL_SENSOR:
      return state;

    case DELETE_VISUAL_MODEL_SENSOR:
      return state;

    default:
      return state;
  }
}

//Visual Model Utilities
const defaultVisualModelUtilitiesState = {
  visualModelUtilitiesData: [],
  totalVisualModelUtilities: 0,
  visualModelUtilitiesSelect: [],
  success: true,
  inProgress: false,
};

export function visualModelUtilitiesReducer(
  state = defaultVisualModelUtilitiesState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_VISUAL_MODEL_UTILITIES_LIST:
      return {
        visualModelUtilitiesData: [],
        success: true,
        deviceTotal: 0,
        visualModelUtilitiesSelect: [],
        inProgress: true,
      };

    case RECEIVE_VISUAL_MODEL_UTILITIES_LIST:
      var _totalVisualModelUtilities = payload.result.length;
      var _selectVisualModelUtilities = [];
      if (payload.result !== []) {
        var _visualModelUtilitiesData = payload.result;

        _visualModelUtilitiesData.forEach((p, index) => {
          var _vm = {
            label: _visualModelUtilitiesData[index].utility_name,
            value: _visualModelUtilitiesData[index].id_utility,
          };
          _selectVisualModelUtilities.push(_vm);
        });
      }
      return Object.assign({}, state, {
        visualModelUtilitiesData: payload,
        success: true,
        totalVisualModelUtilities: _totalVisualModelUtilities,
        visualModelUtilitiesSelect: _selectVisualModelUtilities,
        inProgress: false,
      });

    case FAILED_VISUAL_MODEL_UTILITIES_LIST:
      return {
        visualModelUtilitiesData: [],
        success: false,
        totalVisualModelUtilities: 0,
        visualModelUtilitiesSelect: [],
        inProgress: false,
      };

    case CREATE_VISUAL_MODEL_UTILITIES:
      return state;

    case EDIT_VISUAL_MODEL_UTILITIES:
      return state;

    case DELETE_VISUAL_MODEL_UTILITIES:
      return state;

    default:
      return state;
  }
}
