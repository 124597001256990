import {
  FETCH_SENSOR_LIST,
  RECEIVE_SENSOR_LIST,
  FAILED_SENSOR_LIST,
  EDIT_SENSOR,
  DELETE_SENSOR,
} from "./sensors.types";

const defaultSensorState = {
  sensorData: [],
  totalSensor: 0,
  sensorSelect: [],
  success: true,
  inProgress: false,
};

export function fetchSensorsReducer(state = defaultSensorState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SENSOR_LIST:
      return {
        sensorData: [],
        success: true,
        totalSensor: 0,
        sensorSelect: [],
        inProgress: true,
      };

    case RECEIVE_SENSOR_LIST:
      var _totalSensor = payload.result.length;
      var _selectSensor = [];

      if (payload.result !== []) {
        var _locationData = payload.result;

        _locationData.forEach((p, index) => {
          var _loc = {
            label:
              _locationData[index].sensor_fabricator_name +
              " - " +
              _locationData[index].sensor_fabricator_desc,
            value: _locationData[index].id,
          };
          _selectSensor.push(_loc);
        });
      }
      return Object.assign({}, state, {
        sensorData: payload,
        success: true,
        totalSensor: _totalSensor,
        sensorSelect: _selectSensor,
        inProgress: false,
      });

    case FAILED_SENSOR_LIST:
      return {
        sensorData: [],
        success: false,
        totalSensor: 0,
        sensorSelect: [],
        inProgress: false,
      };

    case EDIT_SENSOR:
      return state;

    case DELETE_SENSOR:
      return state;

    default:
      return state;
  }
}
