import {
  CREATE_SENSOR_CLASS,
  FETCH_SENSOR_CLASS_LIST,
  RECEIVE_SENSOR_CLASS_LIST,
  FAILED_SENSOR_CLASS_LIST,
  EDIT_SENSOR_CLASS,
  DELETE_SENSOR_CLASS,
} from "./sensors.types";

const defaultSensorClassState = {
  sensorClassData: [],
  totalSensorClass: 0,
  sensorClassSelect: [],
  success: true,
  inProgress: false,
};

export function fetchSensorClassListReducer(
  state = defaultSensorClassState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SENSOR_CLASS_LIST:
      return {
        sensorClassData: [],
        success: true,
        totalSensorClass: 0,
        sensorClassSelect: [],
        inProgress: true,
      };
    case RECEIVE_SENSOR_CLASS_LIST:
      var _totalSensorClass = payload.result.length;
      var _selectSensorClass = [];
      if (payload.result !== []) {
        var _locationData = payload.result;
        _locationData.forEach((p, index) => {
          var _loc = {
            label:
              _locationData[index].sensor_class_code +
              " - " +
              _locationData[index].sensor_class_name,
            value: _locationData[index].id,
          };
          _selectSensorClass.push(_loc);
        });
      }
      return Object.assign({}, state, {
        sensorClassData: payload,
        success: true,
        totalSensorClass: _totalSensorClass,
        sensorClassSelect: _selectSensorClass,
        inProgress: false,
      });
    case FAILED_SENSOR_CLASS_LIST:
      return {
        sensorClassData: [],
        success: false,
        totalSensorClass: 0,
        sensorClassSelect: [],
        inProgress: false,
      };
    case CREATE_SENSOR_CLASS:
      return state;
    case EDIT_SENSOR_CLASS:
      return state;
    case DELETE_SENSOR_CLASS:
      return state;
    default:
      return state;
  }
}
