import {
  FETCH_SENSOR_TYPE_LIST,
  RECEIVE_SENSOR_TYPE_LIST,
  FAILED_SENSOR_TYPE_LIST,
} from "./sensors.types";

const defaultSensorTypeState = {
  sensorTypeData: [],
  totalSensorType: 0,
  sensorTypeSelect: [],
  success: true,
  inProgress: false,
};

export function fetchSensorTypeListReducer(
  state = defaultSensorTypeState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SENSOR_TYPE_LIST:
      return {
        sensorTypeData: [],
        success: true,
        totalSensorType: 0,
        sensorTypeSelect: [],
        inProgress: true,
      };
    case RECEIVE_SENSOR_TYPE_LIST:
      var _totalSensorType = payload.result.length;
      var _selectSensorType = [];
      if (payload.result !== []) {
        var _locationData = payload.result;

        _locationData.forEach((p, index) => {
          var _loc = {
            label:
              _locationData[index].sensor_type_name +
              " - " +
              _locationData[index].sensor_type_desc,
            value: _locationData[index].id,
          };
          _selectSensorType.push(_loc);
        });
      }
      return Object.assign({}, state, {
        sensorTypeData: payload,
        success: true,
        totalSensorType: _totalSensorType,
        sensorTypeSelect: _selectSensorType,
        inProgress: false,
      });
    case FAILED_SENSOR_TYPE_LIST:
      return {
        sensorTypeData: [],
        success: false,
        totalSensorType: 0,
        sensorTypeSelect: [],
        inProgress: false,
      };
    default:
      return state;
  }
}
