import {
  FETCH_LL_LOCATION_TYPE_LIST,
  RECEIVE_LL_LOCATION_TYPE_LIST,
  FAILED_LL_LOCATION_TYPE_LIST,
  CREATE_LOCATION_TYPE_LIST,
  EDIT_LOCATION_TYPE,
  DELETE_LOCATION_TYPE,
} from "./location-types.types";

const defaultLLLocationTypeState = {
  llLocationTypeData: [],
  llTotalLocationType: 0,
  llLocationTypeSelect: [],
  success: true,
  inProgress: false,
};

export function locationTypesReducer(
  state = defaultLLLocationTypeState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LL_LOCATION_TYPE_LIST:
      return {
        llLocationTypeData: [],
        success: true,
        llTotalLocationType: 0,
        llLocationTypeSelect: [],
        inProgress: true,
      };

    case RECEIVE_LL_LOCATION_TYPE_LIST:
      var _totalLocationType = payload.result.length;
      var _selectLocationType = [];
      if (payload.result !== []) {
        var _locationTypeData = payload.result;

        _locationTypeData.forEach((p, index) => {
          var _locType = {
            label: _locationTypeData[index].loc_type,
            value: _locationTypeData[index].id,
          };
          _selectLocationType.push(_locType);
        });
      }
      return Object.assign({}, state, {
        llLocationTypeData: payload,
        success: true,
        llTotalLocationType: _totalLocationType,
        llLocationTypeSelect: _selectLocationType,
        inProgress: false,
      });

    case FAILED_LL_LOCATION_TYPE_LIST:
      return {
        llLocationTypeData: [],
        success: false,
        llTotalLocationType: 0,
        llLocationTypeSelect: [],
        inProgress: false,
      };

    case CREATE_LOCATION_TYPE_LIST:
      return state;

    case EDIT_LOCATION_TYPE:
      return state;

    case DELETE_LOCATION_TYPE:
      return state;

    default:
      return state;
  }
}
