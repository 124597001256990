import {
  FETCH_LIVE_MONITORING_DATA,
  RECEIVE_LIVE_MONITORING_DATA,
  FAILED_LIVE_MONITORING_DATA,
  FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
  RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
  FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX,
  FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
  RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
  FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX,
  FETCH_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX,
  RECEIVE_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX,
  FAILED_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX,
} from "./live-monitoring.types";

const defaultLiveDataState = {
  liveData: [],
  success: true,
  inProgress: false,
};

const defaultLiveDataByIdDeviceTimeUnix = {
  liveData: [],
  success: true,
  inProgress: false,
};

const defaultLiveDataByIdDeviceIdSensorTimeUnix = {
  liveData: [],
  success: true,
  inProgress: false,
};

const defaultLiveDataByMultiIdDevicesTimeUnix = {
  liveData: [],
  success: true,
  inProgress: false,
};

export function fetchLiveDataReducer(state = defaultLiveDataState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIVE_MONITORING_DATA:
      return { liveData: [], inProgress: true };
    case RECEIVE_LIVE_MONITORING_DATA:
      return Object.assign({}, state, { liveData: payload, inProgress: false });
    case FAILED_LIVE_MONITORING_DATA:
      return { liveData: [], inProgress: false };
    default:
      return state;
  }
}

export function fetchLiveDataByIdDeviceTimeUnixReducer(
  state = defaultLiveDataByIdDeviceTimeUnix,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX:
      return { liveData: [], inProgress: true };
    case RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX:
      return Object.assign({}, state, { liveData: payload, inProgress: false });
    case FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX:
      return { liveData: [], inProgress: false };
    default:
      return state;
  }
}

export function fetchLiveDataByIdDeviceIdSensorTimeUnixReducer(
  state = defaultLiveDataByIdDeviceIdSensorTimeUnix,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return { liveData: [], inProgress: true };
    case RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return Object.assign({}, state, { liveData: payload, inProgress: false });
    case FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return { liveData: [], inProgress: false };
    default:
      return state;
  }
}

export function fetchLiveDataByMultiIdDevicesTimeUnixReducer(
  state = defaultLiveDataByMultiIdDevicesTimeUnix,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return { liveData: [], inProgress: true };
    case RECEIVE_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return Object.assign({}, state, { liveData: payload, inProgress: false });
    case FAILED_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX:
      return { liveData: [], inProgress: false };
    default:
      return state;
  }
}
