import {
  FETCH_LL_UTILITY_LIST,
  RECEIVE_LL_UTILITY_LIST,
  FAILED_LL_UTILITY_LIST,
  EDIT_LL_UTILITY,
  DELETE_LL_UTILITY,
} from "./utilities.types";

const defaultLLUtilityState = {
  llUtilityData: [],
  llTotalUtility: 0,
  llUtilitySelect: [],
  success: true,
  inProgress: false,
};

export function utilityReducer(state = defaultLLUtilityState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LL_UTILITY_LIST:
      return {
        llUtilityData: [],
        success: true,
        llTotalUtility: 0,
        llUtilitySelect: [],
        inProgress: true,
      };

    case RECEIVE_LL_UTILITY_LIST:
      var _totalUtility = payload.result.length;
      var _selectUtility = [];
      if (payload.result !== []) {
        var _utilityData = payload.result;

        _utilityData.forEach((p, index) => {
          var _util = {
            label:
              _utilityData[index].utility_name +
              " - " +
              _utilityData[index].desc,
            value: _utilityData[index].id,
          };
          _selectUtility.push(_util);
        });
      }
      return Object.assign({}, state, {
        llUtilityData: payload,
        success: true,
        llTotalUtility: _totalUtility,
        llUtilitySelect: _selectUtility,
        inProgress: false,
      });

    case FAILED_LL_UTILITY_LIST:
      return {
        llUtilityData: [],
        success: false,
        llTotalUtility: 0,
        llUtilitySelect: [],
        inProgress: false,
      };

    case EDIT_LL_UTILITY:
      // state.llUtilityData.map((utility) => {
      //     if (utility.id === payload.id) {
      //         return {
      //         ...utility,
      //         ...payload,
      //         };
      //     } else {
      //         return utility;
      //     }
      // });
      return state;

    case DELETE_LL_UTILITY:
      // return state.llUtilityData.filter(({ id }) => id !== payload.id);
      return state;

    default:
      return state;
  }
}
