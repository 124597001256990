import {
  FETCH_USER_TABLE,
  RECEIVE_USER_TABLE,
  FAILED_USER_TABLE,
} from "./users.type";

const defaultUserTableState = {
  userTableData: [],
};

export function fetchUserListReducer(state = defaultUserTableState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_TABLE:
      return { userTableData: [], inProgress: true };
    case RECEIVE_USER_TABLE:
      return Object.assign({}, state, {
        userTableData: payload,
        inProgress: false,
      });
    case FAILED_USER_TABLE:
      return { userTableData: [], inProgress: false };
    default:
      return state;
  }
}
