import {
  UPLOAD_IMAGE,
  RECEIVE_UPLOAD_IMAGE,
  FAILED_UPLOAD_IMAGE,
} from "./upload-image.types";

const defaultUploadImageState = {
  uploadImageData: [],
  success: true,
  inProgress: false,
};

export function uploadImageReducer(state = defaultUploadImageState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_IMAGE:
      return { uploadImageData: [], success: true, inProgress: true };

    case RECEIVE_UPLOAD_IMAGE:
      return Object.assign({}, state, {
        uploadImageData: payload,
        success: true,
        inProgress: false,
      });

    case FAILED_UPLOAD_IMAGE:
      return {
        uploadImageData: [],
        success: false,
        uploadImageSelect: [],
        inProgress: false,
      };

    default:
      return state;
  }
}
