export const FETCH_SENSOR_LIST = 'FETCH_SENSOR_LIST'
export const RECEIVE_SENSOR_LIST = 'RECEIVE_SENSOR_LIST'
export const FAILED_SENSOR_LIST = 'FAILED_SENSOR_LIST'
export const EDIT_SENSOR = 'EDIT_SENSOR'
export const DELETE_SENSOR = 'DELETE_SENSOR'

export const FETCH_SENSOR_CLASS_LIST = 'FETCH_SENSOR_CLASS_LIST'
export const RECEIVE_SENSOR_CLASS_LIST = 'RECEIVE_SENSOR_CLASS_LIST'
export const FAILED_SENSOR_CLASS_LIST = 'FAILED_SENSOR_CLASS_LIST'
export const CREATE_SENSOR_CLASS = 'CREATE_SENSOR_CLASS'
export const EDIT_SENSOR_CLASS = 'EDIT_SENSOR_CLASS'
export const DELETE_SENSOR_CLASS = 'DELETE_SENSOR_CLASS'

export const FETCH_SENSOR_FABRICATOR_LIST = 'FETCH_SENSOR_FABRICATOR_LIST'
export const RECEIVE_SENSOR_FABRICATOR_LIST = 'RECEIVE_SENSOR_FABRICATOR_LIST'
export const FAILED_SENSOR_FABRICATOR_LIST = 'FAILED_SENSOR_FABRICATOR_LIST'
export const CREATE_SENSOR_FABRICATOR_TYPE = 'CREATE_SENSOR_FABRICATOR_TYPE'
export const EDIT_SENSOR_FABRICATOR = 'EDIT_SENSOR_FABRICATOR'
export const DELETE_SENSOR_FABRICATOR = 'DELETE_SENSOR_FABRICATOR'

export const FETCH_SENSOR_TYPE_LIST = 'FETCH_SENSOR_TYPE_LIST'
export const RECEIVE_SENSOR_TYPE_LIST = 'RECEIVE_SENSOR_TYPE_LIST'
export const FAILED_SENSOR_TYPE_LIST = 'FAILED_SENSOR_TYPE_LIST'

export const FETCH_SENSOR_INSTALLATION_LIST = 'FETCH_SENSOR_INSTALLATION_LIST'
export const RECEIVE_SENSOR_INSTALLATION_LIST = 'RECEIVE_SENSOR_INSTALLATION_LIST'
export const FAILED_SENSOR_INSTALLATION_LIST = 'FAILED_SENSOR_INSTALLATION_LIST'
