import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { PERSIST_KEY_NAME } from "../utils/constant";

import coreUiState from "./coreui-state";
import modalState from "./modal-state";
import exportToCsvFromCoreUiDatatable from "./export-to-csv/core-ui-datatable";
import authSlice from "./auth-slice";

import { uploadImageReducer } from "./upload-image.red";
import { fetchLocations } from "./location-list.red";
import { locationTypesReducer } from "./location-types.red";
import {
  devicesReducer,
  fetchDeviceFabricatorListReducer,
} from "./devices.red";
import { fetchSensorsReducer } from "./sensor-list.red";
import { utilityReducer } from "./utilities.red";
import { utilityTypeReducer } from "./utility-types.red";
import { fetchUsageSchedules } from "./usage-schedules.red";
import { fetchResourceUsageTypes } from "./resource-usage-types.red";
import { fetchSensorClassListReducer } from "./sensor-class-list.red";
import { fetchSensorFabricatorListReducer } from "./sensor-fabrication-list.red";
import { fetchSensorTypeListReducer } from "./sensor-type-list.red";
import { fetchDeviceInstallationListReducer } from "./device-installation-list.red";
import { fetchUserListReducer } from "./user-list.red";
import {
  fetchLiveDataReducer,
  fetchLiveDataByIdDeviceTimeUnixReducer,
  fetchLiveDataByIdDeviceIdSensorTimeUnixReducer,
  fetchLiveDataByMultiIdDevicesTimeUnixReducer,
} from "./live-monitoring.red";
import {
  fetchKwhAverageReducer,
  fetchSensorHistoryGroupingReducer,
  fetchAlertHistoryGroupingReducer,
  fetchDeviceActiveTimeReducer,
  fetchNormalPeakTariffReducer,
} from "./processing-live-monitoring.red";
import {
  visualModelReducer,
  visualModelDeviceReducer,
  visualModelSensorReducer,
  visualModelUtilitiesReducer,
} from "./visual-modeling.red";
import { companiesReducer } from "./companies.red";
import { generalSettingReducer, budgetSettingReducer } from "./setting.red";

const persistConfig = {
  key: PERSIST_KEY_NAME,
  storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  modalState,
  coreUiState,
  exportToCsvFromCoreUiDatatable,
  auth: authSlice.reducer,

  uploadImageReducerStore: uploadImageReducer,
  locationsStore: fetchLocations,

  devicesStore: devicesReducer,
  devicesFabricatorStore: fetchDeviceFabricatorListReducer,

  sensorsStore: fetchSensorsReducer,
  sensorClassStore: fetchSensorClassListReducer,
  sensorFabricatorStore: fetchSensorFabricatorListReducer,
  sensorTypeListStore: fetchSensorTypeListReducer,

  deviceInstallationListStore: fetchDeviceInstallationListReducer,

  userTableStore: fetchUserListReducer,

  locationTypesStore: locationTypesReducer,
  usageSchedulesStore: fetchUsageSchedules,
  resourceUsageTypesStore: fetchResourceUsageTypes,
  utilitiesStore: utilityReducer,
  utilityTypesStore: utilityTypeReducer,

  liveDataStore: fetchLiveDataReducer,
  liveDataByIdDeviceTimeUnixStore: fetchLiveDataByIdDeviceTimeUnixReducer,
  liveDataByIdDeviceIdSensorTimeUnixStore:
    fetchLiveDataByIdDeviceIdSensorTimeUnixReducer,
  liveDataByMultiIdDevicesTimeUnixStore:
    fetchLiveDataByMultiIdDevicesTimeUnixReducer,

  processingLiveMonitoringKwhAverageStore: fetchKwhAverageReducer,
  processingLiveMonitoringSensorHistoryGroupingStore:
    fetchSensorHistoryGroupingReducer,
  processingLiveMonitoringSensorAlertGroupingStore:
    fetchAlertHistoryGroupingReducer,
  processingLiveMonitoringDeviceActiveTimeStore: fetchDeviceActiveTimeReducer,
  processingLiveMonitoringNormalPeakTariffStore: fetchNormalPeakTariffReducer,

  visualModelReducerStore: visualModelReducer,
  visualModelSensorReducerStore: visualModelSensorReducer,
  visualModelUtilitiesReducerStore: visualModelUtilitiesReducer,
  visualModelDeviceReducerStore: visualModelDeviceReducer,

  companiesStore: companiesReducer,

  generalSettingStore: generalSettingReducer,
  budgetSettingStore: budgetSettingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
